const baseFitmentFields = ['Year', 'Make', 'Model'];
const extraFitmentFields = ['Submodel'];
const wheelsFields = ['wheel_diameter', 'wheel_width', 'wheel_bolt_pattern'];
const tiresFields = ['tire_width', 'tire_aspect', 'tire_rim'];
const ignoreFields = [...wheelsFields, ...tiresFields, 'Universal'];

const categorySelectionPageUrl = '/shop-by-category/';
const brandSelectionPageUrl = '/shop-by-brand/';

globalThis.Convermax.handleProductCompareClick = () => {
  const productsIdsToCompare = [...window.document.querySelectorAll('input[name="products[]"]:checked')].map(
    (product) => product.dataset.compareId,
  );
  const compareBtn = window.document.body.querySelector('.navUser-item--compare');
  if (!compareBtn) {
    return;
  }

  const countPill = compareBtn.querySelector('.countPill');
  if (countPill) {
    countPill.textContent = productsIdsToCompare.length;
  }

  compareBtn.classList.toggle('show', productsIdsToCompare.length > 1);
  compareBtn.href = `/compare/${productsIdsToCompare.join('/')}/`;
};

export default {
  platform: 'bigcommerce',
  SearchRequestDefaults: {
    pageSize: 36,
  },
  page: {
    getPageType: (defaults) =>
      window.location.pathname === categorySelectionPageUrl ||
      window.location.pathname === brandSelectionPageUrl
        ? 'parts'
        : defaults.getter(),
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    categorySelectionPages: [
      { pathname: categorySelectionPageUrl, field: 'category' },
      { pathname: brandSelectionPageUrl, field: 'brand_name' },
    ],
  },
  facets: {
    rangedFacet: [
      { fields: ['price'], name: 'priceFacet' },
      { fields: ['wheel_offset', 'tire_load_index'], name: 'sliderFacet' },
      { fields: ['wheel_bore'], name: 'sliderFacet', step: '1/10//1', slider: { mode: 'LowerBound' } },
    ],
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: { replace: 'main .page-content' },
      template: 'SearchPage',
      visibleIf: () => window.location.pathname === '/search/',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: '.body .page',
      template: 'SearchPage',
      visibleIf: () =>
        window.document.querySelector('#product-listing-container') &&
        window.location.pathname !== categorySelectionPageUrl &&
        window.location.pathname !== brandSelectionPageUrl,
    },
    {
      name: 'SearchHeader',
      sortSelectClass: 'form-select',
      sortEntries: {
        'relevance': 'Best Match',
        'name': 'A to Z',
        'name:desc': 'Z to A',
        'price': 'Price: Low - High',
        'price:desc': 'Price: High - Low',
      },
    },
    {
      name: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'FacetBarWheels',
      type: 'FacetBar',
      fields: wheelsFields,
    },
    {
      name: 'FacetBarTires',
      type: 'FacetBar',
      fields: tiresFields,
    },
    { name: 'SearchResult' },
    {
      name: 'SearchBox',
      location: { replace: '.header #quickSearch .form' },
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxDialogButton',
      location: {
        firstChildOf: 'body nav.navUser',
        class: 'cm_search-box-root__mobile',
      },
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'HomeVehicleWidget',
      type: 'VehicleWidget',
      location: {
        selector: '#cm-ymm',
        class: 'container',
      },
      template: 'fitmentSearch/homeVehicleWidget',
      columnBreakpoint: 780,
      visibleIf: () => window.location.pathname === '/',
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'Garage',
      location: {
        firstChildOf: '.header .navUser-section',
        class: 'navUser-item navUser-item--garage',
        wrapper: 'li',
      },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'VerifyFitment',
      type: 'VehicleWidget',
      location: {
        insertBefore: '.productView-details.product-options',
        class: 'productView-details product-data',
      },
      template: 'fitmentSearch/verifyFitment',
      visibleIf: { selector: 'input[name="product_id"]' },
    },
    {
      name: 'FitmentTableTab',
      type: 'FitmentTable',
      location: {
        insertAfter: '.productView-description .tabs li:first-child',
        wrapper: 'li',
        class: 'tab',
      },
      template: 'fitmentSearch/fitmentTableTab',
    },
    {
      name: 'FitmentTable',
      location: {
        lastChildOf: '.productView-description .tabs-contents',
        class: 'tab-content is-closed',
        id: 'tab-fitment',
      },
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'FacetCategoryTiles',
      type: 'FacetTiles',
      location: '.body .page',
      template: 'facetTiles/hierarchicalContainer',
      visibleIf: () => window.location.pathname === categorySelectionPageUrl,
    },
    {
      name: 'FacetBrandTiles',
      type: 'FacetTiles',
      location: '.body .page',
      template: 'facetTiles/alphabeticalContainer',
      view: 'grid',
      visibleIf: () => window.location.pathname === brandSelectionPageUrl,
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
      ignoreFields: ['Universal'],
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
  ],
};
